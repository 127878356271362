<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{on}">
      <v-btn v-on="on" icon>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <template v-slot:default>
      <v-sheet rounded class="pt-4">
        <div class="user-data text-center px-4">
          <v-avatar class="elevation-2" size="30">
            <v-icon color="#2196F3" small> mdi-account </v-icon>
          </v-avatar>
          <div class="user-data__username mt-2">
            {{ fullname }}
          </div>
          <div class="user-data__login mt-1">
            {{ userData.email }}
          </div>
        </div>
        <v-list>
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-content> Zmień projekt </v-list-item-content>
            </template>
            <v-list-item
              :class="activeProjectID === project.id ? 'v-list-item--active' : ''"
              dense
              link
              @click.prevent="switchActiveProject(project.id)"
              v-for="(project, idx) in projects"
              :key="idx"
            >
              {{ project.name }}
            </v-list-item>
          </v-list-group>
          <v-list-item link :to="{path: '/profile'}"> Edytuj konto </v-list-item>
          <v-list-item link :to="{path: '/logout'}"> Wyloguj się </v-list-item>
        </v-list>
      </v-sheet>
    </template>
  </v-menu>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

export default {
  name: 'UserMenu',
  data() {
    return {}
  },
  async created() {
    await this.getUserData()
  },
  computed: {
    ...mapState({
      projects: state => state.projects.projectList,
      userData: state => state.userProfile.userData,
    }),
    ...mapGetters(['activeProjectID']),
    fullname() {
      const {firstName, lastName} = this.userData
      return `${firstName} ${lastName}`
    },
  },
  methods: {
    ...mapActions(['getUserData', 'switchProject']),
    switchActiveProject(id) {
      this.switchProject(id)
      this.$router.push({name: 'Dashboard'})
    },
  },
}
</script>

<style scoped lang="scss">
.user-data {
  &__login {
    font-size: 12px;
    color: #757575;
  }

  &__name {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
