<template>
  <div>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="mini = !mini" />
      <v-spacer />
      {{ activeProjectName }}
      <v-spacer />
      <UserMenu />
    </v-app-bar>
    <v-navigation-drawer :mini-variant="mini" dark app>
      <v-list-item link :to="{name: 'Dashboard'}">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Pulpit</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(parent, idx) in navigationRoutes">
        <v-list-group
          v-if="!parent.meta.hideOnSidebar && isGranted(parent.meta.isGranted)"
          no-action
          :prepend-icon="`mdi-${parent.meta.icon}`"
          :key="idx"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ parent.meta.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(rt, index) in parent.children">
            <v-list-item
              v-if="!rt.meta.hideOnSidebar && isGranted(rt.meta.isGranted)"
              :to="{name: rt.name}"
              :key="index"
            >
              {{ rt.meta.name }}
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <h2 v-if="!$route.meta.hideTitle" class="text-h5 mt-6 mb-4">
          {{ $route.meta.pageName }}
        </h2>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import UserMenu from '@/components/AppBar/UserMenu'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    UserMenu,
  },
  data() {
    return {
      mini: false,
    }
  },
  methods: {
    ...mapActions(['refreshToken', 'getProjectList']),
  },
  async created() {
    await this.refreshToken()
    await this.getProjectList()
  },
  computed: {
    ...mapGetters(['activeProjectName', 'isGranted']),
    navigationRoutes() {
      return this.$router.options.routes
    },
  },
}
</script>

<style scoped></style>
